import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomSettingsService } from '../proxy/custom-settings.service';
import { ToasterService } from '@abp/ng.theme.shared';
import {
  API_KEY,
  AZURE_API_KEY,
  AZURE_DEPLOYMENT_NAME,
  MODEL,
  MODEL_OPTIONS,
  PROVIDER,
  AZURE_RESOURCE_NAME,
  EMBEDDING_NAME_OPTIONS,
  HAS_EXISTING_API_KEY,
  AZURE_EMBEDDING_DEPLOYMENT,
  EMBEDDING_NAME,
  SUPPORTED_APIFY_CRAWLER_TYPE_OPTIONS,
  AZURE_MAX_TOKENS,
  AZURE_SPLIT_CHUNK_SIZE,
  AZURE_SPLIT_CHUNK_OVERLAP,
  AZURE_RETRIEVED_DOCUMENT_SPLIT_COUNT,
  AZURE_LLM_PROVIDER_TOKEN_PER_MIN,
  AZURE_CRAWLER_TYPE,
  MAX_TOKENS_DEFAULT_VALUE,
  SPLIT_CHUNK_SIZE_DEFAULT_VALUE,
  SPLIT_CHUNK_OVERLAP_DEFAULT_VALUE,
  RETRIEVED_DOCUMENT_SPLIT_COUNT_DEFAULT_VALUE,
  LLM_PROVIDER_TOKEN_PER_MIN_DEFAULT_VALUE,
  CRAWLER_TYPE_DEFAULT_VALUE,
  AZURE_HAS_EXISTING_API_KEY,
  AZURE_EMBEDDING_NAME,
  MAX_TOKENS,
  SPLIT_CHUNK_SIZE,
  SPLIT_CHUNK_OVERLAP,
  RETRIEVED_DOCUMENT_SPLIT_COUNT,
  LLM_PROVIDER_TOKEN_PER_MIN,
  CRAWLER_TYPE,
} from './openai-integration.consts';
import { CustomSettingsDto } from '../proxy/custom-settings.model';
import {
  AI_FEATURE_CONVERSATION_SUMMARIZATION,
  AI_FEATURE_GENERATIVE_QUESTION_ANSWERING,
  AI_FEATURE_LIVECHAT_SUMMARIZATION,
  AI_FEATURE_PROJECT_GENERATION,
  AI_FEATURE_TEXT_RESPONSE_GENERATION,
  AI_FEATURE_UTTERANCE_GENERATION,
} from 'src/app/virtual-agents/proxy/open-ai/open-ai.const';

@Component({
  selector: 'cai-openai-integration-settings',
  templateUrl: './openai-integration-settings.component.html',
  styleUrls: ['./openai-integration-settings.component.scss'],
})
export class OpenAIIntegrationSettingsComponent implements OnInit {
  form: UntypedFormGroup;
  providerOptions = [];
  modelOptions = [];
  embeddingNameOptions = [];
  crawlerTypeOptions = SUPPORTED_APIFY_CRAWLER_TYPE_OPTIONS;
  selectedProvider;
  azureOpenAIProvider = '';
  openAIProvider;
  isUtteranceGenerationFeatureEnabled = false;
  isTextResponseGenerationFeatureEnabled = false;
  isConversationSummarizationFeatureEnabled = false;
  isLiveChatSummarizationFeatureEnabled = false;
  isProjectGenerationFeatureEnabled = false;
  isGenerativeQuestionAnsweringFeatureEnabled = false;
  isAdvancedSettingsOpen = false;

  hasExistingApiKey = false;

  constructor(
    private config: ConfigStateService,
    private fb: UntypedFormBuilder,
    private settingsService: CustomSettingsService,
    private toaster: ToasterService,
    private localizationService: LocalizationService,
  ) {}

  ngOnInit(): void {
    this.azureOpenAIProvider = this.localizationService.instant(
      'Administration::OpenAiSettings:Provider:AzureOpenAi',
    );
    this.openAIProvider = this.localizationService.instant(
      'Administration::OpenAiSettings:Provider:OpenAi',
    );
    this.providerOptions.push(this.azureOpenAIProvider);
    this.providerOptions.push(this.openAIProvider);
    this.modelOptions = MODEL_OPTIONS;
    this.embeddingNameOptions = EMBEDDING_NAME_OPTIONS;
    this.buildForm();
    this.checkFeatures();
  }

  buildForm() {
    this.selectedProvider = this.config.getSetting(PROVIDER);
    this.hasExistingApiKey =
      this.config.getSetting(
        this.isAzureOpenAiSelected() ? AZURE_HAS_EXISTING_API_KEY : HAS_EXISTING_API_KEY,
      ) === 'true';
    this.isGenerativeQuestionAnsweringFeatureEnabled =
      this.config.getFeature(AI_FEATURE_GENERATIVE_QUESTION_ANSWERING) === 'true';
    this.form = this.fb.group({
      provider: [this.selectedProvider, [Validators.required]],
      apiKey: ['', this.hasExistingApiKey ? [] : [Validators.required]],
      embeddingName: [
        this.config.getSetting(
          this.isAzureOpenAiSelected() ? AZURE_EMBEDDING_NAME : EMBEDDING_NAME,
        ),
        this.isGenerativeQuestionAnsweringFeatureEnabled ? [Validators.required] : [],
      ],
      model: [
        this.config.getSetting(MODEL).trim() === ''
          ? this.modelOptions[0] // gpt-4o
          : this.config.getSetting(MODEL),
        [Validators.required],
      ],
      deploymentName: [this.config.getSetting(AZURE_DEPLOYMENT_NAME), [Validators.required]],
      resourceName: [this.config.getSetting(AZURE_RESOURCE_NAME), [Validators.required]],
      embeddingDeployment: [
        this.config.getSetting(AZURE_EMBEDDING_DEPLOYMENT),
        this.isGenerativeQuestionAnsweringFeatureEnabled ? [Validators.required] : [],
      ],
      maxTokens: [
        !this.config.getSetting(this.isAzureOpenAiSelected() ? AZURE_MAX_TOKENS : MAX_TOKENS)
          ? MAX_TOKENS_DEFAULT_VALUE
          : this.config.getSetting(this.isAzureOpenAiSelected() ? AZURE_MAX_TOKENS : MAX_TOKENS),
        [Validators.required, Validators.min(128), Validators.max(4096)],
      ],
      chunkSize: [
        !this.config.getSetting(
          this.isAzureOpenAiSelected() ? AZURE_SPLIT_CHUNK_SIZE : SPLIT_CHUNK_SIZE,
        )
          ? SPLIT_CHUNK_SIZE_DEFAULT_VALUE
          : this.config.getSetting(
              this.isAzureOpenAiSelected() ? AZURE_SPLIT_CHUNK_SIZE : SPLIT_CHUNK_SIZE,
            ),
        [Validators.required, Validators.min(128), Validators.max(4096)],
      ],
      chunkOverlap: [
        !this.config.getSetting(
          this.isAzureOpenAiSelected() ? AZURE_SPLIT_CHUNK_OVERLAP : SPLIT_CHUNK_OVERLAP,
        )
          ? SPLIT_CHUNK_OVERLAP_DEFAULT_VALUE
          : this.config.getSetting(
              this.isAzureOpenAiSelected() ? AZURE_SPLIT_CHUNK_OVERLAP : SPLIT_CHUNK_OVERLAP,
            ),
        [Validators.required, Validators.min(0), this.validateSplitChunkOverlap.bind(this)],
      ],
      splitCount: [
        !this.config.getSetting(
          this.isAzureOpenAiSelected()
            ? AZURE_RETRIEVED_DOCUMENT_SPLIT_COUNT
            : RETRIEVED_DOCUMENT_SPLIT_COUNT,
        )
          ? RETRIEVED_DOCUMENT_SPLIT_COUNT_DEFAULT_VALUE
          : this.config.getSetting(
              this.isAzureOpenAiSelected()
                ? AZURE_RETRIEVED_DOCUMENT_SPLIT_COUNT
                : RETRIEVED_DOCUMENT_SPLIT_COUNT,
            ),
        [Validators.required, Validators.min(1), Validators.max(10)],
      ],
      llmProviderTokenPerMin: [
        !this.config.getSetting(
          this.isAzureOpenAiSelected()
            ? AZURE_LLM_PROVIDER_TOKEN_PER_MIN
            : LLM_PROVIDER_TOKEN_PER_MIN,
        )
          ? LLM_PROVIDER_TOKEN_PER_MIN_DEFAULT_VALUE
          : this.config.getSetting(
              this.isAzureOpenAiSelected()
                ? AZURE_LLM_PROVIDER_TOKEN_PER_MIN
                : LLM_PROVIDER_TOKEN_PER_MIN,
            ),
        [Validators.required, Validators.min(10000), Validators.max(50000000)],
      ],
      crawlerType: [
        !this.config.getSetting(this.isAzureOpenAiSelected() ? AZURE_CRAWLER_TYPE : CRAWLER_TYPE)
          ? CRAWLER_TYPE_DEFAULT_VALUE
          : this.config.getSetting(
              this.isAzureOpenAiSelected() ? AZURE_CRAWLER_TYPE : CRAWLER_TYPE,
            ),
      ],
    });
  }

  checkFeatures() {
    this.isUtteranceGenerationFeatureEnabled =
      this.config.getFeature(AI_FEATURE_UTTERANCE_GENERATION) === 'true';
    this.isTextResponseGenerationFeatureEnabled =
      this.config.getFeature(AI_FEATURE_TEXT_RESPONSE_GENERATION) === 'true';
    this.isConversationSummarizationFeatureEnabled =
      this.config.getFeature(AI_FEATURE_CONVERSATION_SUMMARIZATION) === 'true';
    this.isLiveChatSummarizationFeatureEnabled =
      this.config.getFeature(AI_FEATURE_LIVECHAT_SUMMARIZATION) === 'true';
    this.isProjectGenerationFeatureEnabled =
      this.config.getFeature(AI_FEATURE_PROJECT_GENERATION) === 'true';
  }
  toggleAdvancedSettings() {
    this.isAdvancedSettingsOpen = !this.isAdvancedSettingsOpen;
  }

  submitForm() {
    if (!this.isFormValid()) {
      return;
    }

    const settings: CustomSettingsDto[] = [];
    settings.push(
      {
        name: PROVIDER,
        value: this.form.get('provider').value,
      },
      {
        name: this.isAzureOpenAiSelected() ? AZURE_HAS_EXISTING_API_KEY : HAS_EXISTING_API_KEY,
        value: this.hasExistingApiKey || this.form.get('apiKey').value ? 'true' : 'false',
      },
      {
        name: this.isAzureOpenAiSelected() ? AZURE_EMBEDDING_NAME : EMBEDDING_NAME,
        value: this.form.get('embeddingName').value,
      },
      {
        name: MODEL,
        value: this.form.get('model').value,
      },
      {
        name: AZURE_DEPLOYMENT_NAME,
        value: this.form.get('deploymentName').value,
      },
      {
        name: AZURE_RESOURCE_NAME,
        value: this.form.get('resourceName').value,
      },
      {
        name: AZURE_EMBEDDING_DEPLOYMENT,
        value: this.form.get('embeddingDeployment').value,
      },
      {
        name: this.isAzureOpenAiSelected() ? AZURE_MAX_TOKENS : MAX_TOKENS,
        value: this.form.get('maxTokens').value.toString(),
      },
      {
        name: this.isAzureOpenAiSelected() ? AZURE_SPLIT_CHUNK_SIZE : SPLIT_CHUNK_SIZE,
        value: this.form.get('chunkSize').value.toString(),
      },
      {
        name: this.isAzureOpenAiSelected() ? AZURE_SPLIT_CHUNK_OVERLAP : SPLIT_CHUNK_OVERLAP,
        value: this.form.get('chunkOverlap').value.toString(),
      },
      {
        name: this.isAzureOpenAiSelected()
          ? AZURE_RETRIEVED_DOCUMENT_SPLIT_COUNT
          : RETRIEVED_DOCUMENT_SPLIT_COUNT,
        value: this.form.get('splitCount').value.toString(),
      },
      {
        name: this.isAzureOpenAiSelected()
          ? AZURE_LLM_PROVIDER_TOKEN_PER_MIN
          : LLM_PROVIDER_TOKEN_PER_MIN,
        value: this.form.get('llmProviderTokenPerMin').value.toString(),
      },
      {
        name: this.isAzureOpenAiSelected() ? AZURE_CRAWLER_TYPE : CRAWLER_TYPE,
        value: this.form.get('crawlerType').value,
      },
    );

    if (this.form.get('apiKey').value) {
      settings.push({
        name: this.isAzureOpenAiSelected() ? AZURE_API_KEY : API_KEY,
        value: this.form.get('apiKey').value,
      });
    }

    this.settingsService.save(settings).subscribe({
      next: () => {
        this.toaster.success('::CustomSettings:Save:Success');
        this.buildForm();
      },
      error: (error) => {
        const businessExceptionErrorCodePrefix = 'Raven:';

        // If the error is caused by a business exception, then the related error message will be shown
        // via global error handling popup, so no need to display an additional error toaster here.
        if (!error?.error?.error?.code.startsWith(businessExceptionErrorCodePrefix)) {
          this.toaster.error('::CustomSettings:Save:Error');
          console.log(error);
        }
      },
    });
  }

  setSelectedProvider() {
    this.selectedProvider = this.form.get('provider').value;
    if (this.isOpenAiSelected()) {
      this.form.patchValue({
        apiKey: this.config.getSetting(API_KEY),
        embeddingName: this.config.getSetting(EMBEDDING_NAME),
        model: this.config.getSetting(MODEL),
        maxTokens: this.config.getSetting(MAX_TOKENS),
        chunkSize: this.config.getSetting(SPLIT_CHUNK_SIZE),
        chunkOverlap: this.config.getSetting(SPLIT_CHUNK_OVERLAP),
        splitCount: this.config.getSetting(RETRIEVED_DOCUMENT_SPLIT_COUNT),
        llmProviderTokenPerMin: this.config.getSetting(LLM_PROVIDER_TOKEN_PER_MIN),
        crawlerType: this.config.getSetting(CRAWLER_TYPE),
      });
    } else {
      this.form.patchValue({
        apiKey: this.config.getSetting(AZURE_API_KEY),
        embeddingName: this.config.getSetting(AZURE_EMBEDDING_NAME),
        deploymentName: this.config.getSetting(AZURE_DEPLOYMENT_NAME),
        resourceName: this.config.getSetting(AZURE_RESOURCE_NAME),
        embeddingDeployment: this.config.getSetting(AZURE_EMBEDDING_DEPLOYMENT),
        maxTokens: this.config.getSetting(AZURE_MAX_TOKENS),
        chunkSize: this.config.getSetting(AZURE_SPLIT_CHUNK_SIZE),
        chunkOverlap: this.config.getSetting(AZURE_SPLIT_CHUNK_OVERLAP),
        splitCount: this.config.getSetting(AZURE_RETRIEVED_DOCUMENT_SPLIT_COUNT),
        llmProviderTokenPerMin: this.config.getSetting(AZURE_LLM_PROVIDER_TOKEN_PER_MIN),
        crawlerType: this.config.getSetting(AZURE_CRAWLER_TYPE),
      });
    }
  }

  isOpenAiSelected() {
    return this.selectedProvider === this.openAIProvider;
  }

  isAzureOpenAiSelected() {
    return this.selectedProvider === this.azureOpenAIProvider;
  }

  isFormValid() {
    const areAdvancedSettingsValid =
      this.form?.get('maxTokens')?.valid &&
      this.form?.get('chunkSize')?.valid &&
      this.form?.get('chunkOverlap')?.valid &&
      this.form?.get('splitCount')?.valid &&
      this.form?.get('llmProviderTokenPerMin')?.valid &&
      this.form?.get('crawlerType')?.valid;

    if (this.isOpenAiSelected()) {
      return (
        this.form.get('provider').value &&
        (this.hasExistingApiKey ? true : this.form.get('apiKey').value) &&
        (this.isGenerativeQuestionAnsweringFeatureEnabled
          ? this.form.get('embeddingName').value
          : true) &&
        this.form.get('model').value &&
        areAdvancedSettingsValid
      );
    } else {
      return (
        this.form.get('provider').value &&
        (this.hasExistingApiKey ? true : this.form.get('apiKey').value) &&
        (this.isGenerativeQuestionAnsweringFeatureEnabled
          ? this.form.get('embeddingName').value
          : true) &&
        this.form.get('deploymentName').value &&
        this.form.get('resourceName').value &&
        (this.isGenerativeQuestionAnsweringFeatureEnabled
          ? this.form.get('embeddingDeployment').value
          : true) &&
        areAdvancedSettingsValid
      );
    }
  }

  validateSplitChunkOverlap(control: FormControl) {
    const splitChunkSize = this.form?.get('chunkSize')?.value;
    if (control.value && Number(control.value) > splitChunkSize) {
      return { splitChunkOverlapShouldNotExceedSplitChunkSize: true }; // Invalid
    } else {
      return null; // Valid
    }
  }
}
