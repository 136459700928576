/* eslint-disable @typescript-eslint/naming-convention */

export const RESPONSE_CODE = {
  intentRecognition: {
    intent: {
      confidence: 0.9,
      name: 'credit',
    },
    intent_ranking: [
      {
        confidence: 0.9,
        name: 'credit',
      },
    ],
  },
  entityExtract: {
    nameOfEntity: 'extractedValueForEntity',
  },
  generativeQuestionAnswering: {
    result: [
      {
        answer: 'answer',
        standalone_question: 'question',
        docs: [
          {
            source: 'file.txt',
            score: 0.20298055250630587,
            content: 'content',
          },
        ],
        logs: {
          openai_duration: 2.017857313156128,
          full_duration: 2.024966239929199,
        },
      },
    ],
  },
};
