import { CoreModule } from '@abp/ng.core';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuillModule } from 'ngx-quill';

import './mention/autoregister';

import { MentionListModalComponent } from './components/mention-list-modal/mention-list-modal.component';
import { RichTextEditorComponent } from './components/rich-text-editor/rich-text-editor.component';
import { RichTextErrorComponent } from './components/rich-text-error/rich-text-error.component';

@NgModule({
  declarations: [RichTextEditorComponent, MentionListModalComponent, RichTextErrorComponent],
  imports: [
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    QuillModule.forRoot({
      theme: 'bubble',
      bounds: 'document.body',
    }),
  ],
  exports: [RichTextEditorComponent],
})
export class RichTextModule {}
