import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

const configureRoutes = (routes: RoutesService) => () => {
  routes.add([
    {
      path: '/',
      name: '::Menu:Home',
      iconClass: 'fas fa-home',
      order: 0,
      layout: eLayoutType.application,
      invisible: true,
    },
    {
      path: '/projects',
      name: '::Menu:Projects',
      layout: eLayoutType.application,
      iconClass: 'fas fa-file-alt',
      order: 1,
      requiredPolicy: 'Designer.Projects',
    },
    {
      path: '/saas',
      name: 'Saas::Menu:Saas',
      iconClass: 'fas fa-users',
      order: 2,
      layout: eLayoutType.application,
      requiredPolicy: 'Saas.Tenants || Saas.Editions',
    },
    {
      path: '/integrations',
      iconClass: 'fas fa-plug',
      name: '::Menu:Integrations',
      layout: eLayoutType.application,
      order: 3,
      requiredPolicy: 'Designer.ChannelIntegrations || Designer.ServiceIntegrations',
    },
  ]);
};

export const APP_ROUTE_PROVIDER = [
  {
    provide: APP_INITIALIZER,
    useFactory: configureRoutes,
    deps: [RoutesService],
    multi: true,
  },
];
