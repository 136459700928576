import { ConfigStateService } from '@abp/ng.core';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateFormatterPipe } from '../pipes/date-formatter.pipe';
import { DateDisplayType } from '../enums/date-display-type';
import { NUMBER_OF_YEAR_OPTIONS } from './date-time-picker.consts';
import { isRtl } from '@utils';

@Component({
  selector: 'cai-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateTimePickerComponent),
    },
  ],
})
export class DateTimePickerComponent implements OnInit, ControlValueAccessor {
  isRTL: boolean;
  currentDate = new Date();

  @ViewChild('dtp', { static: true, read: NgbDatepicker })
  dtp: NgbDatepicker;

  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() disabled: boolean;
  @Input() allowNull: boolean;
  @Output() changed: EventEmitter<Date> = new EventEmitter();

  get formattedMinDate(): NgbDateStruct {
    let minDate = new Date(this.currentDate.getFullYear() - NUMBER_OF_YEAR_OPTIONS, 0, 1);
    if (this.minDate && this.minDate > minDate) {
      minDate = this.minDate;
    }
    return this.getNgbDateStruct(minDate);
  }

  get formattedMaxDate(): NgbDateStruct {
    let maxDate = this.currentDate;
    if (this.maxDate && this.maxDate < maxDate) {
      maxDate = this.maxDate;
    }
    return this.getNgbDateStruct(maxDate);
  }

  ngbDate: NgbDateStruct;
  ngbTime: NgbTimeStruct;
  dateTime: string;

  constructor(
    private dateFormatterPipe: DateFormatterPipe,
    private config: ConfigStateService,
  ) {}

  ngOnInit(): void {
    this.isRTL = isRtl(this.config);
  }

  writeValue(date: Date): void {
    if (date) {
      if (typeof date === 'string') {
        date = new Date(date);
      }
    } else {
      if (!this.allowNull) {
        date = new Date();
        // TODO: Tenant based time-zone setting?
      }
    }

    if (typeof this.minDate === 'string') {
      this.minDate = new Date(this.minDate);
    }
    if (typeof this.maxDate === 'string') {
      this.maxDate = new Date(this.maxDate);
    }

    if (date) {
      this.ngbDate = this.getNgbDateStruct(date);
      this.ngbTime = this.getNgbTimeStruct(date);
      this.setDateTime();
    }
  }

  resetValue() {
    this.ngbDate = null;
    this.ngbTime = null;
    this.dateTime = '';
  }

  registerOnChange(fn: (_: Date) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (_: Date) => void): void {
    this.onTouched = fn;
  }

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  onChange = (_: Date) => {};

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  onTouched = (_: Date) => {};

  onDateSelect() {
    this.checkDate();
    this.emitChanges();
    this.setDateTime();
  }

  onTimeChange() {
    this.checkDate();
    this.emitChanges();
    this.setDateTime();
  }

  checkDate() {
    if (typeof this.ngbDate === 'string') {
      this.ngbDate = this.getNgbDateStruct(this.ngbDate);
    }

    const date = this.combineDateTime(this.ngbDate, this.ngbTime);

    if (this.minDate && date < this.minDate) {
      this.ngbTime = this.getNgbTimeStruct(this.minDate);
    } else if (this.maxDate && date > this.maxDate) {
      this.ngbTime = this.getNgbTimeStruct(this.maxDate);
    }
  }

  getNgbDateStruct(date: Date): NgbDateStruct {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    const ngbDateStruct = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: date.getSeconds(),
    };
    return ngbDateStruct;
  }

  getNgbTimeStruct(date: Date): NgbTimeStruct {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    const ngbTimeStruct = {
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: date.getSeconds(),
    };
    return ngbTimeStruct;
  }

  combineDateTime(date: NgbDateStruct, time: NgbTimeStruct): Date {
    if (date && time) {
      const combinedDate = new Date(
        date.year,
        date.month - 1,
        date.day,
        time.hour,
        time.minute,
        time.second,
      );
      return combinedDate;
    } else {
      const combinedDate = new Date(date.year, date.month - 1, date.day);
      return combinedDate;
    }
  }

  emitChanges(): void {
    const date = this.combineDateTime(this.ngbDate, this.ngbTime);
    this.onChange(date);
    this.changed.emit(date);
  }

  setDateTime() {
    const date = this.combineDateTime(this.ngbDate, this.ngbTime);
    this.dateTime = this.dateFormatterPipe.transform(date, DateDisplayType.shortDateShortTime);

    //TODO:Workaround clear model bug;
    this.dtp.navigateTo({ year: 1900, month: 1 });
    this.dtp.navigateTo(this.getNgbDateStruct(date));
  }
}
