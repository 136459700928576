import { Component, OnInit } from '@angular/core';
import { VersionInfoService } from './version-info.service';
import { ServiceVersionsDto } from './version-info.model';
import { NOT_AVAILABLE, PRODUCT_VERSION_APP_NAME } from '../../shared.consts';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cai-version-info',
  templateUrl: './version-info.component.html',
})
export class VersionInfoComponent implements OnInit {
  serviceVersionsDto: ServiceVersionsDto;
  productVersion: string;
  isDetailsVisible = false;
  loading = true;

  constructor(
    private versionInfoService: VersionInfoService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.versionInfoService.getServiceVersions().subscribe((response) => {
      this.serviceVersionsDto = response;
      this.productVersion =
        this.serviceVersionsDto?.appSetDto?.pods?.find((x) => x?.app === PRODUCT_VERSION_APP_NAME)
          ?.tag ?? NOT_AVAILABLE;

      this.loading = false;
    });
  }

  toggleDetails() {
    this.isDetailsVisible = !this.isDetailsVisible;
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
