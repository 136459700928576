import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  TrackByFunction,
  Type,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { findRoute, getRoutePath, RoutesService, TreeNode } from '@abp/ng.core';
import { collapse } from '@abp/ng.theme.shared';
import {
  CONTENT_AFTER_ROUTES,
  CONTENT_BEFORE_ROUTES,
  LayoutStateService,
} from '@volo/abp.ng.theme.lepton';
import { CaiRoutesService } from '../cai-routes.service';
import { CAI } from '../cai-routes.model';
import { ProjectFlowSummaryDto } from 'src/app/projects/proxy/project.models';

@Component({
  selector: 'cai-routes',
  templateUrl: './cai-routes.component.html',
  styleUrls: ['./cai-routes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [collapse],
})
export class RoutesComponent implements OnInit, AfterViewInit {
  @Output() clickedToLink = new EventEmitter<MouseEvent>();

  @Input() isMenuPlacementTop: boolean;

  @Input() smallScreen: boolean;

  readonly expandedRoutes = new Set<string>();

  readonly initialLevel = 1;

  contentBefore: Type<any>[];
  contentAfter: Type<any>[];

  filterOp = map((routes: TreeNode<CAI.Route>[]) => routes);
  trackByFn: TrackByFunction<TreeNode<CAI.Route>> = (_, item) => item.name;

  get routes$() {
    return this.routes.visible$.pipe(this.filterOp);
  }

  isEditSubFlowModalOpen = false;
  get isSubFlowSavingInProgress() {
    return this.caiRoutesService.isSubFlowSavingInProgress;
  }
  set isSubFlowSavingInProgress(value) {
    this.caiRoutesService.isSubFlowSavingInProgress = value;
  }
  subFlowForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    public readonly routes: RoutesService,
    public readonly injector: Injector,
    private layoutStateService: LayoutStateService,
    public caiRoutesService: CaiRoutesService,
  ) {
    this.contentBefore = injector.get(CONTENT_BEFORE_ROUTES, []);
    this.contentAfter = injector.get(CONTENT_AFTER_ROUTES, []);
  }

  isDropdown(node: TreeNode<CAI.Route>) {
    return !node?.isLeaf || this.routes.hasChildren(node.name);
  }

  isAction(node: TreeNode<CAI.Route>) {
    return node?.onClick;
  }

  isMenuVisible(route: CAI.Route) {
    const isProjectMenu =
      route.isProjectRoute || this.caiRoutesService.isPremiumReportingRoute(route);
    const isProjectMenuOpened = this.caiRoutesService.isProjectMenuOpened;

    return (isProjectMenu && isProjectMenuOpened) || (!isProjectMenu && !isProjectMenuOpened);
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1),
      )
      .subscribe(() => {
        let node = findRoute(this.routes, getRoutePath(this.router));
        node = { parent: node } as TreeNode<CAI.Route>;

        const nodes: string[] = [];
        while (node.parent) {
          node = node.parent;
          nodes.push(node.name);
        }

        let max = nodes.length + this.initialLevel;
        nodes.forEach((name) => this.expandedRoutes.add(name + --max));
      });

    this.caiRoutesService.showSubflowUpdateForm$.subscribe((flow) => {
      this.buildSubFlowForm(flow);
      this.isEditSubFlowModalOpen = true;
    });

    this.caiRoutesService.hideSubflowUpdateForm$.subscribe(() => {
      this.isEditSubFlowModalOpen = false;
    });
  }

  ngAfterViewInit() {
    combineLatest([
      this.layoutStateService.get$('smallScreen'),
      this.layoutStateService.get$('isMenuPlacementTop'),
    ]).subscribe(([smallScreen, isMenuPlacementTop]) => {
      this.smallScreen = smallScreen;
      this.isMenuPlacementTop = isMenuPlacementTop;
    });
  }

  onNavigate({ parentName }: CAI.Route, level: number) {
    let _level = level;
    const nextLevel = () => (_level ? --_level : 0);

    const parents = [(parentName || '') + nextLevel()];
    let node = this.routes.find((item) => item.name === parentName);
    while (node?.parent) {
      node = node.parent;
      parents.push(node.name + nextLevel());
    }

    this.expandedRoutes.forEach((expanded) => {
      if (parents.indexOf(expanded) < 0) this.expandedRoutes.delete(expanded);
    });
  }

  toggleExpand({ name }: CAI.Route, level: number) {
    const has = this.expandedRoutes.has(name + level);
    this.expandedRoutes[has ? 'delete' : 'add'](name + level);

    if (!has) {
      this.collapseDropdowns(name, level);
    }
  }

  collapseDropdowns(name: string, level: number) {
    this.routes.flat
      .filter((route) => route.name !== name && !route.invisible)
      .forEach((route) => {
        this.expandedRoutes.delete(route.name + level);
      });
  }

  private buildSubFlowForm(flow: ProjectFlowSummaryDto) {
    this.subFlowForm = this.fb.group({
      id: new UntypedFormControl(flow.id),
      name: new UntypedFormControl(flow.name, [Validators.required, Validators.maxLength(200)]),
    });
  }

  updateSubFlow() {
    if (this.subFlowForm.invalid || this.isSubFlowSavingInProgress) {
      return;
    }

    this.caiRoutesService.updateSubFlow$.next(this.subFlowForm.getRawValue());
  }

  closeSubFlowModal() {
    this.isEditSubFlowModalOpen = false;
  }
}
