import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RedactionSettingsService } from './proxy/redaction-settings.service';
import {
  RedactionPropertyDto,
  RedactionSettingCreateOrUpdateDto,
  RedactionSettingDto,
} from './proxy/redaction-settings.model';
import { ToasterService } from '@abp/ng.theme.shared';
import { ConfigStateService } from '@abp/ng.core';

@Component({
  selector: 'cai-redaction-settings',
  templateUrl: './redaction-settings.component.html',
  styleUrls: ['./redaction-settings.component.scss'],
})
export class RedactionSettingsComponent implements OnInit {
  form: UntypedFormGroup;
  isLoading: boolean;
  cultureName: string;
  redactionSettings: RedactionSettingDto;

  constructor(
    private service: RedactionSettingsService,
    private fb: UntypedFormBuilder,
    private toaster: ToasterService,
    private configStateService: ConfigStateService,
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.cultureName = this.configStateService.getOne('localization')?.currentCulture.cultureName;
    this.service.get().subscribe({
      next: (response) => {
        this.redactionSettings = response;

        if (this.redactionSettings.isRedactionServiceUnavailable) {
          this.form = this.fb.group({
            isEnableRedaction: [this.redactionSettings.isServiceEnabled],
          });

          this.toaster.error('Exception::Raven:0100163');

          return;
        }

        this.form = this.fb.group({
          isEnableRedaction: [this.redactionSettings.isServiceEnabled],
          redactionMode: [this.redactionSettings.mode.toString()],
          properties: this.fb.group({}),
        });

        this.redactionSettings.properties.forEach((property: RedactionPropertyDto) => {
          (this.form.get('properties') as FormGroup).addControl(
            property.phraseType,
            new FormControl(property.isSelected),
          );
        });

        this.form.addControl('selectAll', new FormControl(this.areAllPropertiesSelected()));
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  submitForm() {
    this.isLoading = true;
    const input: RedactionSettingCreateOrUpdateDto = {
      isServiceEnabled: this.form.get('isEnableRedaction').value,
    };

    if (!this.redactionSettings.isRedactionServiceUnavailable) {
      const propertiesFormGroup = this.form.get('properties') as FormGroup;
      input.mode = this.form.get('redactionMode').value;
      input.phraseTypes = Object.keys(propertiesFormGroup.controls).filter(
        (phraseType) => propertiesFormGroup.get(phraseType).value,
      );
    }

    this.service.createOrUpdate(input).subscribe({
      next: () => {
        this.isLoading = false;
        this.toaster.success('AbpUi::SavedSuccessfully');
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.buildForm();
        this.toaster.error('AbpExceptionHandling::InternalServerErrorMessage');
      },
    });
  }

  getDisplayName(property: RedactionPropertyDto) {
    const displayName = property.phraseInformationList.find(
      (x) => x.languageCode === this.cultureName,
    )?.displayName;

    return displayName;
  }

  getLanguageCodes(property: RedactionPropertyDto) {
    const languageCodes = property.languageCodes.join(',');

    return `(${languageCodes})`;
  }

  onClickedSelectAll(value: boolean) {
    this.form.get('selectAll').setValue(value);
    const propertiesFormGroup = this.form.get('properties') as FormGroup;
    Object.keys(propertiesFormGroup.controls).forEach((phraseType) => {
      propertiesFormGroup.get(phraseType).setValue(value);
    });
  }

  onPhraseTypeChanged() {
    this.form.get('selectAll').setValue(this.areAllPropertiesSelected());
  }

  areAllPropertiesSelected(): boolean {
    const propertiesFormGroup = this.form.get('properties') as FormGroup;
    return Object.keys(propertiesFormGroup.controls).every(
      (phraseType) => propertiesFormGroup.get(phraseType).value === true,
    );
  }
}
