import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { IntegrationInput } from 'src/app/integrations/proxy/integration.model';
import { BotHubSettingsService } from './proxy/bothub-settings.service';

@Component({
  selector: 'cai-bothub-settings',
  templateUrl: './bothub-settings.component.html',
})
export class BotHubSettingsComponent implements OnInit {
  constructor(
    private service: BotHubSettingsService,
    private fb: UntypedFormBuilder,
    private toaster: ToasterService,
  ) {}
  inputs: IntegrationInput[] = [];
  form: UntypedFormGroup;
  isLoading = false;

  ngOnInit() {
    this.setSettings();
  }

  buildForm() {
    this.form = this.fb.group({});
    this.inputs.forEach((integrationInput: IntegrationInput) => {
      this.form.addControl(
        integrationInput.name,
        new UntypedFormControl(
          integrationInput.type === 'bool'
            ? this.convertBoolean(integrationInput.defaultValue)
            : integrationInput.defaultValue,
          integrationInput.isMandatory ? Validators.required : undefined,
        ),
      );
    });
  }

  setSettings() {
    this.service.get().subscribe((response: IntegrationInput[]) => {
      this.inputs = response;
      this.buildForm();
    });
  }

  submitForm() {
    this.isLoading = true;
    const formToSend: any = {};
    this.inputs.forEach((input: IntegrationInput) => {
      if (input.type === 'number') {
        formToSend[input.name] = Number(this.form.value[input.name]);
      } else {
        formToSend[input.name] = this.form.value[input.name];
      }
    });
    this.service.update(formToSend).subscribe(
      () => {
        this.isLoading = false;
        this.toaster.success('AbpUi::SavedSuccessfully');
      },
      (error) => {
        this.isLoading = false;
        console.log(error);
      },
    );
  }

  onChangeNumberInput(value: string, item: IntegrationInput) {
    if (!item.range || item.range.length !== 2) {
      return;
    }
    if (Number(value) < Number(item.range[0])) {
      this.form.patchValue({
        [item.name]: item.range[0],
      });
    } else if (Number(value) > Number(item.range[1])) {
      this.form.patchValue({
        [item.name]: item.range[1],
      });
    } else {
      this.form.patchValue({
        [item.name]: Number(value),
      });
    }
  }

  convertBoolean(value: string) {
    if (value === 'True' || value === 'true') {
      return true;
    } else {
      return false;
    }
  }
}
