import { Component } from '@angular/core';
import { NotificationDto, NotificationModel, NotificationType } from '@sestek/notification';
import { ResponseManagementExportedNotificationModel } from '../models/response-management-exported-notification-model';
import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { FileDownloadHelperService } from 'src/app/shared/services/file-download-helper.service';
import { DateFormatterPipe } from 'src/app/shared/pipes/date-formatter.pipe';
import { DateDisplayType } from 'src/app/shared/enums/date-display-type';
import { PromptsService } from 'src/app/prompts/proxy/prompts.service';

@Component({
  selector: 'cai-response-management-exported-notification',
  templateUrl: './response-management-exported-notification.component.html',
  styleUrls: ['./response-management-exported-notification.component.scss'],
})
@NotificationType('ResponseManagementExportedNotification')
export class ResponseManagementExportedNotificationComponent {
  _data: NotificationDto;

  get data() {
    return this._data;
  }
  set data(data: NotificationDto) {
    this._data = data;
    this.notification = JSON.parse(data.payload);

    this.rawHtml = this.localizationService.instant(
      { key: 'ResponseManagement::ClickHereToDownloadExportFile', defaultValue: '' },
      this.notification.project,
    );
  }

  notification: ResponseManagementExportedNotificationModel;
  rawHtml: string;

  constructor(
    private promptsService: PromptsService,
    private localizationService: LocalizationService,
    private toasterService: ToasterService,
    private fileDownloadHelperService: FileDownloadHelperService,
    private dateFormatterPipe: DateFormatterPipe,
  ) {}

  showNotification(notification: NotificationModel) {
    this.notification = JSON.parse(notification.payload);

    const message = this.localizationService.instant('ResponseManagement::ExportIsReady');

    this.toasterService.success(message);
  }

  downloadFile() {
    let nowString = this.dateFormatterPipe.transform(
      new Date(),
      DateDisplayType.shortDateShortTime,
    );

    nowString = nowString.replace(/[^a-zA-Z0-9]/g, '_');

    const fileName = `${this.localizationService.instant(
      '::Menu:ResponseManagement',
    )}_${nowString}`;

    this.promptsService
      .getExportFile(this.notification.projectId, this.notification.fileId)
      .subscribe({
        next: (result: File) => {
          this.fileDownloadHelperService.handleDownloadFileResult(result, fileName);
        },
        error: (error: any) => {
          this.fileDownloadHelperService.handleDownloadFileError(error);
        },
      });
  }
}
