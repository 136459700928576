import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component } from '@angular/core';
import { NotificationDto, NotificationModel, NotificationType } from '@sestek/notification';

import { ProjectPublishedNotificationModel } from '../models/project-published-notification-model';

@Component({
  selector: 'cai-project-published-notification',
  templateUrl: './project-published-notification.component.html',
  styleUrls: ['./project-published-notification.component.scss'],
})
@NotificationType('ProjectPublishedNotification')
export class ProjectPublishedNotificationComponent {
  _data: NotificationDto;

  get data() {
    return this._data;
  }
  set data(data: NotificationDto) {
    this._data = data;
    this.notification = JSON.parse(data.payload);
  }

  notification: ProjectPublishedNotificationModel;

  constructor(
    private localizationService: LocalizationService,
    private toasterService: ToasterService,
  ) {}

  showNotification(notification: NotificationModel) {
    this.notification = JSON.parse(notification.payload);

    const message = `${this.localizationService.instant('Project::ProjectPublishedSuccessfully')} ${
      this.notification.project
    } (v${this.notification.version.toString()})`;

    this.toasterService.success(message);
  }
}
