import { Component } from '@angular/core';
import { CaiConfigService } from '../../services/cai-config.service';
import { PROJECTS_PATH } from '../../routes/cai-routes.consts';

@Component({
  selector: 'cai-logo',
  template: ` <a class="navbar-brand" [routerLink]="routerLink" alt="logo"></a> `,
})
export class LogoComponent {
  routerLink: string[];

  constructor(private caiConfigService: CaiConfigService) {
    this.routerLink = this.caiConfigService.currentTenant?.id ? [PROJECTS_PATH] : ['/'];
  }
}
