import { escapeHtmlChars } from '@abp/ng.core';
import { EntityProp, EntityPropList, ePropType } from '@abp/ng.components/extensible';
import { eIdentityComponents, IdentityEntityPropContributors } from '@volo/abp.ng.identity';
import { IdentityUserDto } from '@volo/abp.ng.identity/proxy';
import { of } from 'rxjs';

const roleNamesProp = new EntityProp<IdentityUserDto>({
  type: ePropType.String,
  name: 'roleNames',
  displayName: 'AbpIdentity::Roles',
  columnWidth: 250,
  valueResolver: (data) =>
    of(
      data.record.roleNames?.length > 0
        ? data.record.roleNames.reduce((acc, curr) => `${acc}, ${escapeHtmlChars(curr)}`)
        : '',
    ),
});

export function roleNamesContributor(propList: EntityPropList<IdentityUserDto>) {
  const index = propList.findIndex((i) => i.value.name === 'roleNames');
  propList.dropByIndex(index);
  propList.addByIndex(roleNamesProp, index);
}

export const identityEntityPropContributors: IdentityEntityPropContributors = {
  [eIdentityComponents.Users]: [roleNamesContributor],
};
