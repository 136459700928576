<ng-template #modal let-modal>
  <form [formGroup]="form" (ngSubmit)="$event.preventDefault(); $event.stopPropagation()">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ '::ColumnConfiguration:ColumnOptions' | abpLocalization }}
      </h4>
    </div>

    <div
      cdkDropList
      class="modal-body drop-list m-2 pt-0"
      [cdkDropListSortPredicate]="sortPredicateFn"
      (cdkDropListDropped)="onDropRow($event)"
      formArrayName="columns"
      tabindex="1"
      autofocus
    >
      @for (
        columnFormControl of columnsFormArray.controls;
        track columnFormControl;
        let columnIndex = $index
      ) {
        <div
          cdkDrag
          cdkDragBoundary=".modal-body"
          cdkDragLockAxis="y"
          [cdkDragDisabled]="columnFormControl.value?.item.locked"
          class="drop-list-item d-flex flex-row justify-content stretch align-items-center mb-2"
        >
          <div
            cdkDragHandle
            [ngClass]="columnFormControl.value?.item.locked ? '' : 'drag-handle'"
            class="text-center flex-grow-0 flex-shrink-0 pe-2"
          >
            <i
              [style.margin]="'auto'"
              [style.opacity]="columnFormControl.value?.item.locked ? '0.3' : '1'"
              class="fa-solid fa-grip-dots-vertical"
            >
            </i>
          </div>
          <div class="flex-grow-1 flex-shrink-1">
            <ng-select
              appendTo="body"
              placeholder="{{ '::ColumnConfiguration:PleaseSelect' | abpLocalization }}"
              [clearable]="false"
              [searchable]="false"
              [items]="selectionItems"
              [readonly]="columnFormControl.value?.item.locked"
              [compareWith]="selectionCompareFn"
              [formControl]="columnFormControl"
            >
              <ng-template ng-option-tmp let-selectionItem="item">
                {{ selectionItem.item.header | abpLocalization }}
              </ng-template>
              <ng-template ng-label-tmp let-selectionItem="item">
                {{ selectionItem.item.header | abpLocalization }}
              </ng-template>
            </ng-select>
          </div>
          <div class="text-center flex-grow-0 flex-shrink-0 ps-2">
            @if (columnFormControl.value?.item.locked) {
              <i class="fa-duotone fa-lock"></i>
            }
            @if (!columnFormControl.value?.item.locked) {
              <i
                class="fa-solid fa-xmark cursor-pointer"
                (click)="onClickRemoveColumn($event, columnIndex)"
              >
              </i>
            }
          </div>
        </div>
      }
    </div>

    <div class="modal-footer d-flex justify-content-between">
      <div
        [ngbTooltip]="'::ColumnConfiguration:ReachedTheColumnLimit' | abpLocalization"
        [disableTooltip]="!allColumnsSelected"
      >
        <button
          type="button"
          class="btn btn-outline-primary btn-sm"
          [disabled]="allColumnsSelected"
          (click)="onClickAddColumn($event)"
        >
          <i class="far fa-plus-circle me-1"></i>
          {{ '::ColumnConfiguration:AddColumn' | abpLocalization }}
        </button>
      </div>

      <div class="d-flex" style="gap: 8px">
        <button type="button" class="btn btn-outline-primary btn-sm" (click)="onClickReset($event)">
          <i class="fa-regular fa-right-left me-1"></i>
          {{ '::ColumnConfiguration:Reset' | abpLocalization }}
        </button>

        <button type="button" class="btn btn-outline-primary btn-sm" (click)="modal.dismiss()">
          <i class="far fa-rotate-left me-1"></i>
          {{ 'AbpUi::Cancel' | abpLocalization }}
        </button>

        <button type="submit" class="btn btn-primary btn-sm" (click)="onClickSave($event)">
          <i class="far fa-floppy-disk me-1"></i>
          {{ '::Save' | abpLocalization }}
        </button>
      </div>
    </div>
  </form>
</ng-template>
