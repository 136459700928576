import { ConfigStateService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CaiConfigService {
  constructor(private config: ConfigStateService) {}

  isFeatureEnabled(key: string): boolean {
    return this.config.getFeature(key) === 'true';
  }

  get isRtl() {
    const localize = this.config.getOne('localization');
    return localize?.currentCulture.cultureName === 'ar';
  }

  get isLanguageTurkish() {
    const localize = this.config.getOne('localization');
    return localize?.currentCulture.cultureName === 'tr';
  }

  get isLanguageArabic() {
    const localize = this.config.getOne('localization');
    return localize?.currentCulture.cultureName === 'ar';
  }

  get currentTenant() {
    return this.config.getOne('currentTenant');
  }

  get currentUser() {
    return this.config.getOne('currentUser');
  }

  get auth() {
    return this.config.getOne('auth');
  }
}
