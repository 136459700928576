export const TOOLTIP_DELAY = 400;

export const FONT_FAMILY_NUNITO_SANS = 'Nunito Sans';

export const FILTER_PANEL_NAMES = {
  dashboard: 'Dashboard',
  conversations: 'Conversations',
  generativeQuestionAnsweringRecognitions: 'GenerativeQuestionAnsweringRecognitions',
  outbound: 'Outbound',
};

export const ID_LIST_OF_CONVERSATIONS_ON_CURRENT_PAGE_CACHE_KEY = 'conversationsCurrentPageIdList';

export const DESIGNER_FLOW_LINK_NAMES_VISIBLE_CACHE_KEY = 'linkNamesVisible';

export const PROJECT_CREATION_SESSION_KEY = 'projectCreation';

export const PROJECT_CREATION_VA_INDEX_SESSION_KEY = 'projectCreationVaIndex';

export const COLUMN_CONFIGURATOR_KEY = 'columnConfigurator';

export const PROJECT_FLOWS_AND_VIRTUAL_AGENTS_KEY = 'projectFlowsAndVirtualAgents';

export const OUTBOUND_TEST_CALL_PARAMETERS_KEY = 'outboundTestCallParameters';

export const PROJECT_VIRTUAL_AGENT_INTENT_GROUP_KEY = 'selectedProjectVirtualAgentAndIntentGroups';

export const TOASTER_LIFE = 5000;

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export const PRODUCT_VERSION_APP_NAME = 'cai-magpie-api';

export const NOT_AVAILABLE = 'N/A';

export const ARABIC_LANGUAGE_CODE = 'ar-SA';

export const FLOW_VERSION_CANNOT_BE_UPDATED_ERROR_CODE = 'Raven:0100148';

export const NLU_INTEGRATION_ERROR_CODE = 'Raven:0100117';

export const COMMA_KEY_CODE = 188;
export const TAB_KEY_CODE = 9;
export const ENTER_KEY_CODE = 13;
