import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component } from '@angular/core';
import { NotificationDto, NotificationModel, NotificationType } from '@sestek/notification';

import { ProjectImportedNotificationModel } from '../models/project-imported-notification-model';

@Component({
  selector: 'cai-project-imported-notification',
  templateUrl: './project-imported-notification.component.html',
  styleUrls: ['./project-imported-notification.component.scss'],
})
@NotificationType('ProjectImportedNotification')
export class ProjectImportedNotificationComponent {
  _data: NotificationDto;

  get data() {
    return this._data;
  }
  set data(data: NotificationDto) {
    this._data = data;
    this.notification = JSON.parse(data.payload);
  }

  notification: ProjectImportedNotificationModel;

  constructor(
    private localizationService: LocalizationService,
    private toasterService: ToasterService,
  ) {}

  showNotification(notification: NotificationModel) {
    this.notification = JSON.parse(notification.payload);

    const message = `${this.localizationService.instant('Project::ProjectImportedSuccessfully')} ${
      this.notification.project
    }`;

    this.toasterService.success(message);

    const payload = JSON.parse(notification.payload);
    if (payload.showIntegrationWarning) {
      const warningMessage = `${this.localizationService.instant(
        'Project::ProjectImportIntegrationWarning',
      )}`;
      this.toasterService.warn(warningMessage, null, { sticky: true });
    }
  }
}
