export class ObjectHelper {
  static deepCopy<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj)) as T;
  }

  // items.sort(compareBy('name'))
  static compareBy = <TProp extends PropertyKey>(prop: TProp) => {
    return (a: { [k in TProp] }, b: { [k in TProp] }) =>
      a[prop] < b[prop] ? -1 : a[prop] > b[prop] ? 1 : 0;
  };

  static getEnumValues<E extends EnumObject>(enumObject: E): EnumObjectEnum<E>[] {
    return Object.keys(enumObject)
      .filter((key) => Number.isNaN(Number(key)))
      .map((key) => enumObject[key] as EnumObjectEnum<E>);
  }

  static getEnumKeyValuePairs<E extends EnumObject>(
    enumObject: E,
  ): { key: EnumObjectEnum<E>; value: E[EnumObjectEnum<E>] }[] {
    return this.getEnumValues(enumObject).map((key) => ({ key, value: enumObject[key] }));
  }

  static getKeyValues(obj: Record<string, any>): KeyValuePair[] {
    return Object.keys(obj).map((key) => {
      const value = obj[key];

      if (value && Array.isArray(value)) {
        if (value.every((item) => typeof item === 'string')) {
          return { key, value };
        }

        return {
          key,
          value: value.map((item, index) =>
            typeof item === 'object' && item !== null
              ? { key: index.toString(), value: this.getKeyValues(item) }
              : { key: index.toString(), value: item },
          ),
        };
      }

      if (typeof value === 'object' && value !== null) {
        return { key, value: this.getKeyValues(value) };
      }

      return { key, value };
    });
  }
}

export type KeyValuePair = {
  key: string;
  value: string | string[] | KeyValuePair[];
};

type EnumObject = { [key: string]: number | string };
type EnumObjectEnum<E extends EnumObject> = E extends { [key: string]: infer ET | string }
  ? ET
  : never;
