import { Component } from '@angular/core';
import { NotificationDto, NotificationModel, NotificationType } from '@sestek/notification';
import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';

import { GenerativeQuestionAnsweringTrainFailedNotificationModel } from 'src/app/projects/proxy/project.models';
import { ProjectService } from 'src/app/projects/proxy/project.service';

@Component({
  selector: 'cai-project-generative-qna-train-failed-notification',
  templateUrl: './project-generative-qna-train-failed-notification.component.html',
  styleUrls: ['./project-generative-qna-train-failed-notification.component.scss'],
})
@NotificationType('GenerativeQuestionAnsweringTrainFailedNotification')
export class ProjectGenerativeQnaTrainFailedNotificationComponent {
  _data: NotificationDto;

  get data() {
    return this._data;
  }
  set data(data: NotificationDto) {
    this._data = data;
    this.notification = JSON.parse(data.payload);
  }

  notification: GenerativeQuestionAnsweringTrainFailedNotificationModel;
  constructor(
    private localizationService: LocalizationService,
    private toasterService: ToasterService,
    private projectService: ProjectService,
  ) {}

  showNotification(notification: NotificationModel) {
    this.notification = JSON.parse(notification.payload);

    this.projectService.generativeQuestionAnsweringTrainFailedSubject.next(this.notification);

    const message = this.localizationService.instant(
      'Project::GenerativeQuestionAnsweringTrainingFailed',
      this.notification.name,
    );

    this.toasterService.error(message);
  }
}
