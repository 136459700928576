import { Component, OnInit } from '@angular/core';
import { CaiRoutesService } from '../../routes/cai-routes.service';
import { ProjectRouteDataModel } from '../../routes/cai-routes.model';
import { PROJECTS_PATH } from '../../routes/cai-routes.consts';

@Component({
  selector: 'cai-notification-icon',
  template: `<sestek-notification-icon [routerLinkBase]="routerLinkBase">
  </sestek-notification-icon>`,
})
export class NotificationIconComponent implements OnInit {
  routerLinkBase = [];

  constructor(public caiRoutesService: CaiRoutesService) {}

  ngOnInit() {
    this.caiRoutesService.currentProjectRouteData$.subscribe(
      (projectRouteData: ProjectRouteDataModel) => {
        if (projectRouteData.projectId) {
          this.routerLinkBase = [PROJECTS_PATH, projectRouteData.projectId];
        } else {
          this.routerLinkBase = [];
        }
      },
    );
  }
}
