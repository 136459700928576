import { Component } from '@angular/core';
import { NotificationDto, NotificationModel, NotificationType } from '@sestek/notification';
import { ToasterService } from '@abp/ng.theme.shared';

import { LocalizationService } from '@abp/ng.core';
import { ProjectExportFailedNotificationModel } from '../models/project-export-failed-notification-model';

@Component({
  selector: 'cai-project-export-failed-notification',
  templateUrl: './project-export-failed-notification.component.html',
  styleUrls: ['./project-export-failed-notification.component.scss'],
})
@NotificationType('ProjectExportFailedNotification')
export class ProjectExportFailedNotificationComponent {
  _data: NotificationDto;

  get data() {
    return this._data;
  }
  set data(data: NotificationDto) {
    this._data = data;
    this.notification = JSON.parse(data.payload);
  }

  notification: ProjectExportFailedNotificationModel;

  constructor(
    private localizationService: LocalizationService,
    private toasterService: ToasterService,
  ) {}

  showNotification(notification: NotificationModel) {
    this.notification = JSON.parse(notification.payload);

    const message = this.localizationService.instant('Project::ProjectExportFailedMessage');

    this.toasterService.error(message);
  }
}
