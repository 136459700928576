@if (form) {
  <form [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="mt-2 fade-in-top">
      <div class="new-integration-form">
        @for (input of inputs; track input) {
          <div class="form-group">
            @if (input.type === 'bool') {
              <input
                class="form-check-input me-1"
                id="{{ input.name }}"
                formControlName="{{ input.name }}"
                type="checkbox"
              />
            }
            <label
              for="{{ input.name }}"
              [style]="input.type === 'bool' ? 'margin-top: 2px;' : undefined"
            >
              {{ ('::' + input.name | abpLocalization) + (input.isMandatory ? ' *' : '') }}
            </label>
            @if (input.type === 'text' || input.type === 'number') {
              <input
                id="{{ input.name }}"
                formControlName="{{ input.name }}"
                class="form-control"
                [type]="input.isMasked ? 'password' : input.type"
                [min]="input.range[0]"
                [max]="input.range[1]"
                (keyup)="onChangeNumberInput($event.target.value, input)"
              />
            }
          </div>
        }
      </div>
    </div>
    <abp-button
      (click)="submitForm()"
      iconClass="fa fa-check"
      [loading]="isLoading"
      [disabled]="form.invalid"
      class="ms-1"
    >
      {{ 'AbpUi::Save' | abpLocalization }}
    </abp-button>
  </form>
}
