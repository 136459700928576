<div class="modal-header">
  <h4 class="modal-title d-flex justify-content-center">
    <abp-logo></abp-logo>
  </h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12 float-left">
      <b>{{ '::ProductVersion' | abpLocalization }}:</b>
      <span>
        @if (loading) {
          <i class="fa fa-spinner fa-pulse fa-fw ms-1"></i>
        }
        {{ productVersion }}
      </span>
    </div>
  </div>

  <br />

  <p (click)="toggleDetails()" style="text-decoration: underline; cursor: pointer">
    {{ (isDetailsVisible ? '::HideDetails' : '::ShowDetails') | abpLocalization }}
  </p>

  @if (isDetailsVisible) {
    <div>
      @for (pod of serviceVersionsDto?.appSetDto?.pods; track pod) {
        <div class="row">
          <div class="col-md-12 float-left">
            <b>{{ pod?.app }}:</b> {{ pod?.tag }}
          </div>
        </div>
      }
      <br />
      <div class="row">
        <div class="col-md-12 float-left">
          <b>{{ '::MLProvider' | abpLocalization }}:</b>
          {{ serviceVersionsDto?.nluProviderDto?.nluProvider }}
        </div>
        @if (serviceVersionsDto?.nluProviderDto?.nluClientBaseAddress !== '') {
          <div class="col-md-12 float-left">
            <b>{{ '::MLUrl' | abpLocalization }}:</b>
            {{ serviceVersionsDto?.nluProviderDto?.nluClientBaseAddress }}
          </div>
        }
      </div>
    </div>
  }
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="closeModal()">
    {{ 'AbpUi::Close' | abpLocalization }}
  </button>
</div>
