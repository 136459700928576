import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { ServiceVersionsDto, VersionInfoDto } from './version-info.model';

@Injectable({
  providedIn: 'root',
})
export class VersionInfoService {
  apiName = 'Default';
  constructor(private restService: RestService) {}

  getVersionInfo = () =>
    this.restService.request<void, VersionInfoDto>(
      {
        method: 'GET',
        url: `/api/version`,
      },
      { apiName: this.apiName },
    );

  getServiceVersions = () =>
    this.restService.request<void, ServiceVersionsDto>(
      {
        method: 'GET',
        url: `/api/app/version`,
      },
      { apiName: this.apiName },
    );
}
