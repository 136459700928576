@if (currentUser$ | async; as user) {
  @if (user.isAuthenticated) {
    <div class="dropdown btn-group" ngbDropdown>
      <a ngbDropdownToggle class="btn pointer d-flex">
        <abp-current-user-image
          *abpReplaceableTemplate="{
            componentKey: currentUserImageComponentKey,
            inputs: { currentUser: { value: user }, classes: { value: 'user-avatar' } },
          }"
          classes="user-avatar"
        ></abp-current-user-image>
        <!--
        <span class="ms-1">
          @if ((selectedTenant$ | async)?.name; as tenantName) {
            <small>
              <i>{{ tenantName }}</i
              >\
            </small>
          }
          <span>{{ user.userName }}</span>
        </span>
        -->
      </a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end" data-bs-popper="none">
        <div class="p-2 row">
          <div class="pe-0 col col-auto">
            <abp-current-user-image
              *abpReplaceableTemplate="{
                componentKey: currentUserImageComponentKey,
                inputs: { currentUser: { value: user }, classes: { value: 'user-avatar-big' } },
              }"
              classes="user-avatar-big"
            ></abp-current-user-image>
          </div>
          <div class="ps-2 col">
            <span>{{ 'AbpAccount::Welcome' | abpLocalization }}</span
            ><br />
            @if ((selectedTenant$ | async)?.name; as tenantName) {
              <small>
                <i>{{ tenantName }}</i
                >\
              </small>
            }
            <strong>{{
              ((user.name || '') + ' ' + (user.surName || '')).trim() || user.userName
            }}</strong>
          </div>
        </div>
        <div class="dropdown-divider"></div>

        @for (item of userMenu.items$ | async; track $index) {
          <ng-container *abpVisible="!item.visible || item.visible(item)">
            <li class="nav-item d-flex align-items-center" *abpPermission="item.requiredPolicy">
              @if (item.component) {
                <ng-container
                  [ngComponentOutlet]="item.component"
                  [ngComponentOutletInjector]="item | toInjector"
                ></ng-container>
              } @else {
                @if (item.html) {
                  <div [innerHTML]="item.html" (click)="item.action ? item.action() : null"></div>
                } @else {
                  @if (item.textTemplate) {
                    <a (click)="item.action ? item.action() : null" class="dropdown-item pointer">
                      @if (item.textTemplate.icon) {
                        <i class="me-1 {{ item.textTemplate.icon }}"></i>
                      }
                      {{ item.textTemplate.text | abpLocalization }}
                    </a>
                  }
                }
              }
            </li>
          </ng-container>
        }
      </div>
    </div>
  } @else {
    <a role="button" class="btn" (click)="navigateToLogin()">
      <i class="fas fa-sign-in-alt me-1" aria-hidden="true"></i>
      {{ 'AbpAccount::Login' | abpLocalization }}</a
    >
  }
}
