import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { IntegrationInput } from 'src/app/integrations/proxy/integration.model';
const PATH = '/api/app/bothub-settings';

@Injectable({
  providedIn: 'root',
})
export class BotHubSettingsService {
  apiName = 'Default';

  get = () =>
    this.restService.request<void, IntegrationInput[]>(
      {
        method: 'GET',
        url: `${PATH}`,
      },
      { apiName: this.apiName },
    );

  update = (input: any) =>
    this.restService.request<any, void>(
      {
        method: 'PUT',
        url: `${PATH}`,
        body: input,
      },
      { apiName: this.apiName },
    );

  constructor(private restService: RestService) {}
}
