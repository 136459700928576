import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TreeSelectComponent } from './tree-select.component';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { CoreModule } from '@abp/ng.core';

@NgModule({
  imports: [FormsModule, CommonModule, NzTreeSelectModule, CoreModule],
  declarations: [TreeSelectComponent],
  exports: [TreeSelectComponent],
})
export class TreeSelectModule {}
