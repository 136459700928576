import { Component } from '@angular/core';
import { CaiRoutesService } from '../../routes/cai-routes.service';

@Component({
  selector: 'cai-project-edit-icon',
  template: `@if (caiRoutesService.currentProjectId) {
    <div>
      <a
        class="btn shadow-none bubble-btn"
        [routerLink]="['/projects', caiRoutesService.currentProjectId, 'edit', 'general']"
        [queryParams]="{ source: 'designer' }"
        [ngbTooltip]="'Project::ProjectSettings' | abpLocalization"
        *abpPermission="'Designer.Projects.Edit'"
      >
        <i class="fa fa-gear fa-lg"></i>
      </a>
    </div>
  }`,
})
export class ProjectEditIconComponent {
  constructor(public caiRoutesService: CaiRoutesService) {}
}
