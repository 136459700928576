import { APP_INITIALIZER, inject } from '@angular/core';
import { NavItemsService, UserMenuService } from '@abp/ng.theme.shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VersionInfoComponent } from './version-info/version-info.component';
import { ProjectEditIconComponent } from './project-edit-icon/project-edit-icon.component';
import { NotificationIconComponent } from './notification-icon/notification-icon.component';
import { eThemeLeptonComponents } from '@volo/abp.ng.theme.lepton';
import { CurrentUserComponent } from './current-user/current-user.component';

export const CUSTOM_NAV_ITEMS = [
  {
    provide: APP_INITIALIZER,
    useFactory: addCustomNavItems,
    multi: true,
  },
];

export function addCustomNavItems() {
  const userMenu = inject(UserMenuService);
  const navItems = inject(NavItemsService);
  const modalService = inject(NgbModal);

  return () => {
    userMenu.addItems([
      {
        id: 'UserMenu.About',
        order: 101,
        textTemplate: {
          text: '::About',
        },
        action: () => {
          modalService.open(VersionInfoComponent, {
            ariaLabelledBy: 'modal-basic-title',
            centered: true,
            size: 'md',
          });
        },
        visible: () => true,
      },
    ]);

    navItems.items.find((i) => i.id === eThemeLeptonComponents.CurrentUser).component =
      CurrentUserComponent;

    navItems.addItems([
      {
        id: 'ProjectEditIcon',
        order: 99.1,
        component: ProjectEditIconComponent,
      },
      {
        id: 'NotificationIcon',
        order: 99.2,
        component: NotificationIconComponent,
      },
    ]);
  };
}
