import { Component } from '@angular/core';
import { NotificationDto, NotificationModel, NotificationType } from '@sestek/notification';
import { ToasterService } from '@abp/ng.theme.shared';

import { LocalizationService } from '@abp/ng.core';
import { ProjectImportFailedNotificationModel } from '../models/project-import-failed-notification-model';

@Component({
  selector: 'cai-project-import-failed-notification',
  templateUrl: './project-import-failed-notification.component.html',
  styleUrls: ['./project-import-failed-notification.component.scss'],
})
@NotificationType('ProjectImportFailedNotification')
export class ProjectImportFailedNotificationComponent {
  _data: NotificationDto;

  get data() {
    return this._data;
  }
  set data(data: NotificationDto) {
    this._data = data;
    this.notification = JSON.parse(data.payload);
  }

  notification: ProjectImportFailedNotificationModel;

  constructor(
    private localizationService: LocalizationService,
    private toasterService: ToasterService,
  ) {}

  showNotification(notification: NotificationModel) {
    this.notification = JSON.parse(notification.payload);

    const message = this.localizationService.instant(
      `${
        this.notification.errorCode
          ? 'Exception::' + this.notification.errorCode
          : 'Project::ProjectImportFailedMessage'
      }`,
    );

    this.toasterService.error(message);
  }
}
