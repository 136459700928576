<div
  ngbDropdown
  #dropDownPicker
  class="d-inline-block w-100"
  [style]="disabled ? 'pointer-events: none;' : ''"
>
  <div class="input-group">
    <input
      class="form-control form-control-sm"
      name="inputDate"
      readonly
      [(ngModel)]="dateTime"
      ngbDropdownToggle
    />
    @if (allowNull) {
      <div class="input-group-text">
        <button class="btn btn-link" (click)="resetValue()" type="button">
          {{ '::Clear' | abpLocalization }}
        </button>
      </div>
    }
  </div>
  <div ngbDropdownMenu aria-labelledby="inputDate">
    <div class="row main">
      <div class="col-12">
        <div class="row m-1 {{ isRTL ? 'rtl-datepicker' : '' }}">
          <ngb-datepicker
            #dtp
            id="dtp"
            [maxDate]="formattedMaxDate"
            [minDate]="formattedMinDate"
            (dateSelect)="onDateSelect()"
            name="dtp"
            class="picker"
            [(ngModel)]="ngbDate"
          >
          </ngb-datepicker>
        </div>
        <div class="row justify-content-center pt-3">
          <ngb-timepicker
            (change)="onTimeChange()"
            name="time"
            [(ngModel)]="ngbTime"
            [spinners]="false"
          >
          </ngb-timepicker>
        </div>
      </div>
    </div>
  </div>
</div>
