import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApplicationSettingsDto } from './app-settings.model';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  apiName = 'Default';
  applicationSettings$ = new BehaviorSubject<ApplicationSettingsDto>(null);

  constructor(private restService: RestService) {}

  init() {
    return this.getApplicationSettings().toPromise();
  }

  private getApplicationSettings = () =>
    this.restService
      .request<void, ApplicationSettingsDto>(
        {
          method: 'GET',
          url: `/api/app/application-settings`,
        },
        { apiName: this.apiName },
      )
      .pipe(
        tap((applicationSettings) => {
          this.applicationSettings$.next(applicationSettings);
        }),
      );
}

export const initializeApplicationSettings = (appSettingsService: AppSettingsService) => {
  return (): Promise<any> => {
    return appSettingsService.init();
  };
};
